<template>
  <div class="login">
    <v-form ref="form" v-model="form" class="pa-5">
      <h1>כניסה למערכת ניהול</h1>
      <v-stepper
        class="transparent"
        flat
        elevation-0
        touchless
        v-model="stepper"
      >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-text-field
              :rules="mail_rules"
              v-model="phone"
              class="mb-10"
              :label="'מספר טפלון'"
            ></v-text-field>
            <!--/text filed -->
            <v-text-field
              :rules="passwordRules"
              v-model="password"
              :type="!visibility ? 'password' : 'text'"
              :label="'סיסמה'"
            >
              <template slot="append">
                <v-btn @click="visibility = !visibility" small icon>
                  <v-icon color="grey lighten-1">
                    {{ visibility ? "visibility" : "visibility_off" }}
                    <!--/ visiblility of password -->
                  </v-icon>
                </v-btn>
              </template>
              <!--/ template -->
            </v-text-field>
            <!--/ password  -->
          </v-stepper-content>
          <v-stepper-content step="2">
            <h3 class="mb-2">كود التحقق</h3>
            <v-otp-input
              style="direction: ltr"
              v-if="stepper == 2"
              v-model="code"
              :rules="[
                (v) => !!v || 'حقل مطلوب',
                !otp_fails || 'كود التحقق غير صحيح',
              ]"
              length="4"
            ></v-otp-input>
            <div class="d-flex align-center">
              <v-btn
                @click="rsend()"
                color="primary"
                icon
                :disabled="timer != 0"
              >
                <v-icon>sync</v-icon>
              </v-btn>
              <span
                :class="[
                  { 'red--text': timer < 10 },
                  { 'primary--text': timer >= 10 },
                ]"
                class="font-weight-bold ms-1"
                >{{ timer }}</span
              >
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <div class="d-flex">
        <v-btn
          @click="stepper == 1 ? validate() : check_code()"
          :disabled="login_loading"
          width="160"
          x-large
          rounded
          color="primary"
          :loading="login_loading"
        >
          {{ "כניסה" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          target="_blank"
          text
          href="#"
          class="text-transform-underline black--text font-weight-bold"
        >
          {{ "תמיכה!" }}
        </v-btn>
      </div>
    </v-form>
    <!--/ form -->
    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "done" }}</v-icon
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      stepper: 1,
      login_loading: false,
      otp_fails: false,
      code: null,
      timer: 60,
      snackbar: {
        prop: false,
        err: true,
        errColor: "red lighteen-2",
        succColor: "primary",
        errText: "יש שגיאה במספר טלפון או סיסמה!",
        succText: "סבבה, נכנסתה בהצלחה!",
      },
      form: false,
      visibility: false,
      phone: "",
      mail_rules: [(v) => !!v || "Mail number is required"],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },

  methods: {
    start_timer() {
      let set = setInterval(() => {
        this.timer -= 1;
        if (this.timer == 0) {
          clearInterval(set);
        }
      }, 1000);
    },
    rsend() {
      if (this.timer == 0) {
        this.timer = 60;
        this.$nextTick(() => {
          this.$store
            .dispatch("login", {
              phone_number: this.phone,
              password: this.password,
            })
            .then(() => {
              this.start_timer();
            });
        });
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.login_loading = true; //  make submit button loading
        this.$store
          .dispatch("login", {
            phone_number: this.phone,
            password: this.password,
          })
          .then((res) => {
            if (!res.data.data.otp) {
              this.snackbar.prop = true; // fade alert
              this.snackbar.err = false; // remove error from alert
              if (!res.data.data.is_company) {
                // this.$router.push({ name: "companies" });
                window.location.reload(); 
              } else {

                window.location.reload();
                // this.$router.push({ name: "orders" });
              }
            } else {
              this.stepper = 2;
              this.login_loading = false;
              this.start_timer();
            }
            console.log(res);
          })
          .catch((err) => {
            this.login_loading = false; // remove submit loading
            this.snackbar.prop = true; // fade alert
            this.snackbar.err = true; // fade error of alert
          });
      }
    },

    check_code() {
      if (!this.login_loading) {
        this.login_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `check_code/${this.code}`,
              method: "post",
            },
          })
          .then((res) => {
            const data = res.data.data;
            this.snackbar.err = false; // remove error from alert
            this.snackbar.prop = true; // fade alert
            this.$store.commit("login", data.token);
            this.$store.commit("user", data);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.v-form {
  width: 28rem;
}
h1 {
  margin-bottom: 100px;
}
</style>
