var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('v-form',{ref:"form",staticClass:"pa-5",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('h1',[_vm._v("כניסה למערכת ניהול")]),_c('v-stepper',{staticClass:"transparent",attrs:{"flat":"","elevation-0":"","touchless":""},model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-text-field',{staticClass:"mb-10",attrs:{"rules":_vm.mail_rules,"label":'מספר טפלון'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-text-field',{attrs:{"rules":_vm.passwordRules,"type":!_vm.visibility ? 'password' : 'text',"label":'סיסמה'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.visibility = !_vm.visibility}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" "+_vm._s(_vm.visibility ? "visibility" : "visibility_off")+" ")])],1)],1)],2)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('h3',{staticClass:"mb-2"},[_vm._v("كود التحقق")]),(_vm.stepper == 2)?_c('v-otp-input',{staticStyle:{"direction":"ltr"},attrs:{"rules":[
              (v) => !!v || 'حقل مطلوب',
              !_vm.otp_fails || 'كود التحقق غير صحيح',
            ],"length":"4"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","icon":"","disabled":_vm.timer != 0},on:{"click":function($event){return _vm.rsend()}}},[_c('v-icon',[_vm._v("sync")])],1),_c('span',{staticClass:"font-weight-bold ms-1",class:[
                { 'red--text': _vm.timer < 10 },
                { 'primary--text': _vm.timer >= 10 },
              ]},[_vm._v(_vm._s(_vm.timer))])],1)],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"disabled":_vm.login_loading,"width":"160","x-large":"","rounded":"","color":"primary","loading":_vm.login_loading},on:{"click":function($event){_vm.stepper == 1 ? _vm.validate() : _vm.check_code()}}},[_vm._v(" "+_vm._s("כניסה")+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-transform-underline black--text font-weight-bold",attrs:{"target":"_blank","text":"","href":"#"}},[_vm._v(" "+_vm._s("תמיכה!")+" ")])],1)],1),_c('v-snackbar',{attrs:{"width":"auto","right":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-icon',_vm._b({attrs:{"color":_vm.snackbar.err ? _vm.snackbar.errColor : _vm.snackbar.succColor}},'v-icon',attrs,false),[_vm._v(_vm._s(_vm.snackbar.err ? "highlight_off" : "done"))])]}}]),model:{value:(_vm.snackbar.prop),callback:function ($$v) {_vm.$set(_vm.snackbar, "prop", $$v)},expression:"snackbar.prop"}},[_vm._v(" "+_vm._s(_vm.snackbar.err ? _vm.snackbar.errText : _vm.snackbar.succText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }